import { Box, Button, Flex, Heading, Text, Link } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HOME_NAMESPACE } from "../locales";
import { LanguageSwitcher } from "./LanguageSwitcher"
import { ReactComponent as LogoIcon } from "../static/Logo.svg";
import HeaderBackground from "../static/HeaderBackground.png";
import MobilePhonesEN from "../static/MobilePhonesEN.png";
import MobilePhonesPT from "../static/MobilePhonesPT.png";
import { ReactComponent as CheckWhitekIcon } from "../static/CheckWhite.svg";
// import { ReactComponent as PlayIcon } from "../static/Video.svg";
import { ReactComponent as AppleIcon } from "../static/SocialApple.svg";
import { ReactComponent as PlayStoreIcon } from "../static/SocialPlayStore.svg";

export const Header: React.FC = () => {
  const { t, i18n } = useTranslation(HOME_NAMESPACE);

  const locale = i18n.language;

  const headerImages = {
    en: MobilePhonesEN,
    pt: MobilePhonesPT
  };

  //@ts-ignore
  const imageUrl = headerImages[locale];

  return (
    <Flex
      flexDirection="column"
      minHeight={["730px", "1000px"]}
      backgroundImage={HeaderBackground}
      backgroundSize={["300% 110%", "100% 100%"]}
      backgroundPosition="top center">
      <Flex justifyContent={{ base: "center", lg: "flex-end" }}>
        <Box mr={{ base: 0, lg: "25%" }} mt={{ base: 4, lg: 8 }}>
          <LanguageSwitcher />
        </Box>
      </Flex>
      <Flex
        maxWidth="768px"
        mx="auto"
        flexDirection="column"
        alignItems="center"
        color="white.100">
        <Box width={["150px", "200px"]} mt={[5, 10]} mb={[5, 10]}>
          <LogoIcon width="100%" />
        </Box>
        <Heading variant="h2" textAlign="center" mb={3}>{t("header.title")}</Heading>
        <Text textAlign="center">{t("header.subtitle")}</Text>
        <Flex alignItems="center" my={[5, 10]}>
          <Button variant="primary" leftIcon={<AppleIcon />} as={Link} href="https://apps.apple.com/us/app/plux/id1635957274" target="_blank" width="50%">
            <Box>
              <Heading variant="h7">{t("header.availableAt")}</Heading>
              <Text>{t("header.appStore")}</Text>
            </Box>
          </Button>
          <Button variant="primary" leftIcon={<PlayStoreIcon />} as={Link} href="https://play.google.com/store/apps/details?id=com.pluxapp.mobile.android" target="_blank" width="50%" ml={3}>
            <Box>
              <Heading variant="h7">{t("header.availableAt")}</Heading>
              <Text>{t("header.googlePlay")}</Text>
            </Box>
          </Button>
        </Flex>
        <Flex flexDirection={["column", null, "row"]} alignItems={["center", null]}>
          <Flex alignItems="center"><CheckWhitekIcon width="12px" /> <Heading variant="h6" ml={1}>{t("header.bullet1")}</Heading></Flex>
          <Flex alignItems="center" mx={[0, 5]} my={[1, 0]}><CheckWhitekIcon width="12px" /> <Heading variant="h6" ml={1}>{t("header.bullet2")}</Heading></Flex>
          <Flex alignItems="center"><CheckWhitekIcon width="12px" /> <Heading variant="h6" ml={1}>{t("header.bullet3")}</Heading></Flex>
        </Flex>
      </Flex>
      <Flex
        backgroundImage={imageUrl}
        flexGrow={1}
        width="100%"
        maxWidth="maxWidth"
        mx="auto"
        backgroundPosition="top center"
        backgroundSize="cover"
        alignItems="center"
        justifyContent="center"
      >
        {/* <Button
          variant="secondary"
          boxShadow="0px 2px 80px rgba(108,125,188, 1)"
          leftIcon={<PlayIcon />}
          as={ReactLink}
          to="/#"
          target="_blank">
          {t("header.seeVideo")}
        </Button> */}
      </Flex>
    </Flex>
  );
};
