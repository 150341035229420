import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { SHARED_NAMESPACE } from "./locales";
import { default as PT } from "./locales/pt.json";
import { default as EN } from "./locales/en.json";

export { SHARED_NAMESPACE } from "./locales";
export { Footer } from "./footer/Footer";
export { TextContent } from "./textContent/TextContent";
export { Metadata } from "./metadata";

export class SharedModule implements AppModule {
    setupTranslations() {
        registerResourceBundle(SUPPORTED_LANGUAGES.pt, SHARED_NAMESPACE, PT);
        registerResourceBundle(SUPPORTED_LANGUAGES.en, SHARED_NAMESPACE, EN);
    }
}
