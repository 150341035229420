import React from "react";
import { Helmet } from "react-helmet";

interface Props {
  title?: string;
  description?: string;
  image?: string;
  url?: string;
  canonical?: string;
  type?: string;
  prev?: string;
  next?: string;
}

export const Metadata: React.FC<Props> = ({
  title,
  description,
  image,
  url,
  canonical,
  type,
  prev,
  next
}) => (
  <Helmet>
    {title && <title>{title}</title>}
    {description && (
      <meta name="description" content={description} />
    )}
    {title && <meta property="og:title" content={title} />}
    {description && (
      <meta property="og:description" content={description} />
    )}
    {image && <meta property="og:image" content={image} />}
    {url && <meta property="og:url" content={url} />}
    {canonical && <link rel="canonical" href={canonical} />}
    {type && <meta property="og:type" content={type} />}
    {prev && <link rel="prev" href={prev} />}
    {next && <link rel="next" href={next} />}
  </Helmet>
)
