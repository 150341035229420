import { Box, Heading } from "@chakra-ui/react";
import React from "react";
import { Trans } from "react-i18next";

interface Props {
  namespace: string;
  contenti18: string;
}

export const TextContent: React.FC<Props> = ({ namespace, contenti18 }) => (
  <Box>
    <Trans
      ns={namespace}
      i18nKey={contenti18}
      components={{
        h2: <Heading variant="h2" />,
        h3: <Heading variant="h3" />,
        h4: <Heading variant="h4" />,
        h5: <Heading variant="h5" fontWeight="500" />,
        h6: <Heading variant="h6" fontWeight="500" />,
        h7: <Heading variant="h7" fontWeight="500" />,
        b: <Box as="b" />,
        li: <Box as="li" ml={3} />
      }}
    />
  </Box >
)
