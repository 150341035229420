module.exports = {
  production: true,
  api: "https://api.pluxapp.com/v1/public/app",
  hCaptchaSiteKey: "bb3a7407-7085-400f-81ce-1d0d6255de71",
  automaise: {
    id: "d72d2f77-1713-1b99-9b55-6944d520b6de",
    key: "7579864b-67d0-4c46-f0e5-01f8a0d56fc4"
  },
  googleAnalyticsTrackingId: "G-GT4HJ7D5Z7",
  googleTagManagerTrackingId: "AW-11112664463"
};
