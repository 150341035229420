import { Box, Flex, Heading, useBreakpointValue } from "@chakra-ui/react";
import React from "react";
import { HowItWorksElement } from "./HowItWorksElement";
import { ReactComponent as DownloadIcon } from "../static/DownloadFill.svg";
import { ReactComponent as AccountIcon } from "../static/AccountFill.svg";
import { ReactComponent as VehicleIcon } from "../static/VehicleSmall.svg";
import { ReactComponent as ServiceIcon } from "../static/ServicesFill.svg";
import { ReactComponent as WorkshopIcon } from "../static/MapFill.svg";
import { ReactComponent as SlotIcon } from "../static/ScheduleFill.svg";
import { ReactComponent as NotificationIcon } from "../static/NotificationFill.svg";

export interface HowItWorksItem {
  title: string;
  subtitle: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  orientation: "left" | "right";
}

export const HowItWorks: React.FC = () => {
  const isMobile = useBreakpointValue({ base: true, xs: true, sm: false });
  const items: HowItWorksItem[] = [
    {
      title: "howItWorks.download.title",
      subtitle: "howItWorks.download.subtitle",
      icon: DownloadIcon,
      orientation: "left"
    },
    {
      title: "howItWorks.account.title",
      subtitle: "howItWorks.account.subtitle",
      icon: AccountIcon,
      orientation: "right"
    },
    {
      title: "howItWorks.vehicle.title",
      subtitle: "howItWorks.vehicle.subtitle",
      icon: VehicleIcon,
      orientation: "left"
    },
    {
      title: "howItWorks.service.title",
      subtitle: "howItWorks.service.subtitle",
      icon: ServiceIcon,
      orientation: "right"
    },
    {
      title: "howItWorks.workshop.title",
      subtitle: "howItWorks.workshop.subtitle",
      icon: WorkshopIcon,
      orientation: "left"
    },
    {
      title: "howItWorks.slot.title",
      subtitle: "howItWorks.slot.subtitle",
      icon: SlotIcon,
      orientation: "right"
    },
    {
      title: "howItWorks.notification.title",
      subtitle: "howItWorks.notification.subtitle",
      icon: NotificationIcon,
      orientation: "left"
    }
  ];

  return (
    <Box maxWidth="750px" mx="auto" mt={10} px={[7, 0]}>
      {items.map((item, index) => {
        const isFirst = index === 0;
        const isLast = index === (items.length - 1);
        const showRight = item.orientation === "right" || isMobile;

        return (
          <Flex key={index}>
            {!isMobile && (
              <Flex
                width="50%"
                justifyContent="center"
                alignItems="center"
                sx={{
                  "&::after":
                    item.orientation === "left" ?
                      {
                        content: "''",
                        width: 0,
                        height: 0,
                        border: "15px solid transparent",
                        borderLeftColor: "white.100",
                        mr: 3
                      } : {}
                }}>
                {item.orientation === "left" && (
                  <HowItWorksElement item={item}  />
                )}
              </Flex>
            )}
            <Flex
              width={["100%", "50%"]}
              position="relative"
              justifyContent="center"
              alignItems="center"
              background="linear-gradient(var(--chakra-colors-primary-300), var(--chakra-colors-primary-300))"
              backgroundPosition={isFirst ? "bottom left" : "top left"}
              backgroundSize={isFirst || isLast ? "2px 50%" : "2px 100%"}
              backgroundRepeat="no-repeat"
              pt={[3, 0]}
              sx={{
                "&::before":
                showRight ? {
                    content: "''",
                    width: 0,
                    height: 0,
                    border: "15px solid transparent",
                    borderRightColor: "white.100",
                    ml: 3
                  } : {}
              }}>
              {showRight && (
                <HowItWorksElement item={item} />
              )}
              <Flex
                position="absolute"
                backgroundColor="white.100"
                left="-17px"
                borderRadius="default">
                <Heading variant="h5" backgroundColor="primary.300" borderRadius="default" px={4} py={2}>
                  {index + 1}
                </Heading>
              </Flex>
            </Flex>
          </Flex>
        );
      })}
    </Box>
  );
};
