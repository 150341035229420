import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input, Textarea } from "@chakra-ui/react";
import React, { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { HOME_NAMESPACE } from "../locales";
import { ReactComponent as CheckBlackIcon } from "../static/CheckBlack.svg";
import { ReactComponent as NetworkIcon } from "../static/MapFill.svg";
import { ReactComponent as SendIcon } from "../static/Send.svg";
import HCaptcha from "@hcaptcha/react-hcaptcha";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "network";
import environment from "configurations";

interface ContactForm {
  name: string;
  email: string;
  message: string;
}

const formInitialState = { name: "", email: "", message: "" };

export const JoinNetwork: React.FC = () => {
  const { t } = useTranslation(HOME_NAMESPACE);
  const [captchaToken, setCaptchaToken] = useState<string | undefined>()
  const captchaRef = useRef<any>(null);

  const ContactFormValidationSchema = Yup.object().shape({
    name: Yup.string().min(3, t("joinNetwork.tooShort")).required(t("joinNetwork.required")),
    email: Yup.string().email(t("joinNetwork.validEmail")).required(t("joinNetwork.required")),
    message: Yup.string().min(10, t("joinNetwork.tooShort")).required(t("joinNetwork.required"))
  });

  return (
    <Flex id="contact" flexDirection="column" alignItems="center" maxWidth="675px" mx="auto" mt={10} px={[7, 0]}>
      <NetworkIcon width="24px" />
      <Heading variant="h2" textAlign="center" my={3}>{t("joinNetwork.title")}</Heading>
      <Heading variant="h5" textAlign="center">{t("joinNetwork.subtitle")}</Heading>

      <Flex flexDirection={["column", null, "row"]} alignItems={["center", null]} my={[5, 10]}>
        <Flex alignItems="center"><CheckBlackIcon width="12px" /> <Heading variant="h6" ml={1}>{t("joinNetwork.bullet1")}</Heading></Flex>
        <Flex alignItems="center" mx={[0, 5]} my={[1, 0]}><CheckBlackIcon width="12px" /> <Heading variant="h6" ml={1}>{t("joinNetwork.bullet2")}</Heading></Flex>
        <Flex alignItems="center"><CheckBlackIcon width="12px" /> <Heading variant="h6" ml={1}>{t("joinNetwork.bullet3")}</Heading></Flex>
      </Flex>

      
      <Formik<ContactForm>
        initialValues={{...formInitialState}}
        validationSchema={ContactFormValidationSchema}
        onSubmit={async (values, actions) => {
          try {
            await axios.post(
              `contacts`,
              {
                token: captchaToken,
                type: "JoinWorkshop",
                name: values.name,
                email: values.email,
                message: values.message
              }
            );
            actions.resetForm({ values: {...formInitialState} });
          } catch (err: any) {
            actions.setFieldError("message", err.message);
          }

          actions.setSubmitting(false);
        }}>
        {(props) => {
          return (
            <Form style={{ width: "100%" }}>
              <Flex flexDirection={["column", "row"]} width="100%">
                <Field name="name">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors[field.name] !== undefined &&
                        form.touched[field.name] !== undefined
                      }
                      mr={[0, 1]}>
                      <FormLabel>
                        {t("joinNetwork.name")}
                      </FormLabel>
                      <Input {...field} type="text" />
                      <FormErrorMessage>
                        {form.errors[field.name]}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
                <Field name="email">
                  {({ field, form }: FieldProps) => (
                    <FormControl
                      isRequired
                      isInvalid={
                        form.errors[field.name] !== undefined &&
                        form.touched[field.name] !== undefined
                      }
                      ml={[0, 1]} mt={[5, 0]}>
                      <FormLabel>
                        {t("joinNetwork.email")}
                      </FormLabel>
                      <Input {...field} type="email" />
                      <FormErrorMessage>
                        {form.errors[field.name]}
                      </FormErrorMessage>
                    </FormControl>
                  )}
                </Field>
              </Flex>

              <Field name="message">
                {({ field, form }: FieldProps) => (
                  <FormControl
                    isRequired
                    isInvalid={
                      form.errors[field.name] !== undefined &&
                      form.touched[field.name] !== undefined
                    }
                    mt={5}>
                    <FormLabel>
                      {t("joinNetwork.message")}
                    </FormLabel>
                    <Textarea {...field} />
                    <FormErrorMessage>
                      {form.errors[field.name]}
                    </FormErrorMessage>
                  </FormControl>
                )}
              </Field>

              <Flex width="100%" flexDirection={["column", "row"]} justifyContent="space-between" alignItems={["flex-start", "center"]} mt={5}>
                <HCaptcha
                  sitekey={environment.hCaptchaSiteKey}
                  onVerify={(token) => setCaptchaToken(token)}
                  ref={captchaRef}
                  size="normal"
                />
                <Button
                  type="submit"
                  variant="primary"
                  disabled={!props.dirty || !props.isValid || !captchaToken}
                  isLoading={props.isSubmitting}
                  rightIcon={<SendIcon width="24px" />}
                  iconSpacing="20px"
                  mt={[5, 0]}>
                  {t("joinNetwork.submit")}
                </Button>
              </Flex>
            </Form>
          );
        }}
      </Formik>
    </Flex>
  );
};
