import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HOME_NAMESPACE } from "../locales";
import { ReactComponent as ServicesIcon } from "../static/ServicesFill.svg";
import { ReactComponent as SettingsIcon } from "../static/SettingsFill.svg";
import GrayBackground from "../static/GrayBackground.svg";
import { ServicesCarousel } from "./ServicesCarousel";
import { HowItWorks } from "./HowItWorks";

export const Content: React.FC = () => {
  const { t } = useTranslation(HOME_NAMESPACE);

  return (
    <Box mt={10}>
      <Flex id="services" flexDirection="column" alignItems="center" px={[7, 0]}>
        <ServicesIcon width="24px" />
        <Heading variant="h2" textAlign="center" my={3}>{t("services.title")}</Heading>
        <Heading variant="h5" textAlign="center">{t("services.subtitle")}</Heading>
      </Flex>

      <Box backgroundColor="primary.400" mt={10} pb={10}>
        <ServicesCarousel />

        <Flex id="how-it-works" flexDirection="column" alignItems="center" mt={10} px={[7, 0]}>
          <SettingsIcon width="24px" />
          <Heading variant="h2" textAlign="center" my={3}>{t("howItWorks.title")}</Heading>
          <Heading variant="h5" textAlign="center">{t("howItWorks.subtitle")}</Heading>
        </Flex>

        <HowItWorks />
      </Box>
      <Box
        height={["100px", "200px"]}
        backgroundImage={GrayBackground}
        backgroundSize="contain"
        backgroundPosition="top left"
        backgroundRepeat="no-repeat"
      />
    </Box >
  );
};
