import React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme";
import Fonts from "theme/Fonts";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Home } from "app/home";
import { ThemeShowCase } from "theme/ThemeShowcase";
import { Legal } from "app/legal";
import { About } from "app/about";
import { Metadata } from "app/shared";
import { LoadAppScripts } from "LoadAppScripts";
import { HOME_NAMESPACE } from "app/home/locales";
import { useTranslation } from "react-i18next";
import { DeleteAccount } from "app/account";

const App = () => {
  const locationUrl = `${window.location.origin}${window.location.pathname}${window.location.search}`;

  const { t } = useTranslation(HOME_NAMESPACE);

  return (
    <ChakraProvider theme={theme}>
      <Fonts />
      <BrowserRouter>
        <Metadata
          title={t('metadata.title')}
          description="Plux, a app que ajuda o seu carro! Em 5 minutos agende revisão auto, troca de pneus, comprar bateria carro, reparação chapa automóvel e mais. Descarregue já!"
          url={locationUrl}
          canonical={locationUrl}
          type="website"
        />
        <LoadAppScripts />
        <Routes>
          <Route path="/*" element={<Home />} />
          <Route path="/legal" element={<Legal />} />
          <Route path="/about" element={<About />} />
          <Route path="/delete-account" element={<DeleteAccount />} />
          <Route path="/showcase" element={<ThemeShowCase />} />
        </Routes>
      </BrowserRouter>
    </ChakraProvider>
  );
}

export default App;
