import React, { useState } from "react";
import { HOME_NAMESPACE } from "../locales";
import { useTranslation } from "react-i18next";
import { ReactComponent as PTIcon } from '../static/PT.svg';
import { ReactComponent as USIcon } from '../static/US.svg';
import { ReactComponent as ArrowDown } from '../static/ArrowDownFill.svg';
import { Box } from '@chakra-ui/react';

export const LanguageSwitcher: React.FC = () => {
  const { t, i18n } = useTranslation(HOME_NAMESPACE);

  const lang = i18n.language.substring(0, 2)
  const defaultLang = ['pt', 'en'].includes(lang) ? lang : 'pt'

  const [value, setValue] = useState(defaultLang);

  const handleChange = (event: any) => {
    i18n.changeLanguage(event.target.value);
    setValue(event.target.value);
  };

  const iconMapping = {
    "en": USIcon,
    "pt": PTIcon
  }

  //@ts-ignore
  const SelectedIcon = iconMapping[value]

  return (
    <Box position="relative" display="inline-block">
      <Box
        as="select"
        value={value}
        onChange={handleChange}
        sx={{
          appearance: "none",
          paddingRight: "1.5em",
          "-webkit-appearance": "none", /* for Safari */
          color: "white",
          position: "absolute",
          top: 0,
          left: 0,
          opacity: 0,
          width: "100%",
          height: "100%",
          "&:focus option": {
            color: "black"
          }
        }}>
        <option value="en">{t("header.locales.en")}</option>
        <option value="pt">{t("header.locales.pt")}</option>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          color: "white",
          fontSize: "13px",
          paddingRight: "1rem",
          "& span": {
            marginRight: "0.2rem",
            marginLeft: "0.5rem"
          }
        }}
      >
        {value && (
          <>
            <SelectedIcon />
            <Box as="span">{value.toUpperCase()}</Box>
            <ArrowDown />
          </>
        )}
      </Box>
    </Box>
  );
}
