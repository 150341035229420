import { Box, Container, Tabs, TabList, TabPanels, Tab, TabPanel, Heading } from "@chakra-ui/react";
import { Metadata, TextContent } from "app/shared";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { LEGAL_NAMESPACE } from "../locales";
import { useSearchParams } from "react-router-dom";
import { SUPPORTED_LANGUAGES } from "i18n";

const Legal: React.FC = () => {
  const { t, i18n } = useTranslation(LEGAL_NAMESPACE);
  const [searchParams, setsearchParams] = useSearchParams();
  const tabsDefinition = [
    { index: 0, name: "terms" },
    { index: 1, name: "privacy" },
    { index: 2, name: "cookies" }
  ];

  useEffect(() => {
    const lang = searchParams.get("lang");

    if (lang && Object.values(SUPPORTED_LANGUAGES).includes(lang)) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%" height="100%" minHeight="100vh" backgroundColor="primary.400" py={5}>
      <Metadata
        title={t('metadata.title')}
        description="Plux, a app do carro! Agende serviços sem telefonemas em 5 minutos: troca de pneus, revisão geral, substituição de baterias, reparação de pintura e mais!"
      />
      <Container maxWidth="800px">
        <Tabs
          variant="primary"
          defaultIndex={tabsDefinition.find(it => it.name === searchParams.get("tab"))?.index ?? 0}
          onChange={index => setsearchParams({ tab: tabsDefinition.find(it => it.index === index)?.name ?? "" })}>
          <TabList>
            <Tab><Heading variant="h5">{t("termsAndConditions.title")}</Heading></Tab>
            <Tab><Heading variant="h5">{t("privacyPolicy.title")}</Heading></Tab>
            <Tab><Heading variant="h5">{t("cookiesPolicy.title")}</Heading></Tab>
          </TabList>

          <Box background="white.100" borderRadius="default" mt={5}>
            <TabPanels>
              <TabPanel>
                <TextContent namespace={LEGAL_NAMESPACE} contenti18="termsAndConditions.content" />
              </TabPanel>
              <TabPanel>
                <TextContent namespace={LEGAL_NAMESPACE} contenti18="privacyPolicy.content" />
              </TabPanel>
              <TabPanel>
                <TextContent namespace={LEGAL_NAMESPACE} contenti18="cookiesPolicy.content" />
              </TabPanel>
            </TabPanels>
          </Box>
        </Tabs>
      </Container>
    </Box>
  );
}

export default Legal;
