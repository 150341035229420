import { Box, Container } from "@chakra-ui/react";
import { Metadata, TextContent } from "app/shared";
import React from "react";
import { useTranslation } from 'react-i18next';
import { ABOUT_NAMESPACE } from "../locales";

const About: React.FC = () => {
  const { t } = useTranslation(ABOUT_NAMESPACE);

  return (
    <Box width="100%" height="100%" minHeight="100vh" backgroundColor="primary.400" py={5}>
      <Metadata
        title={t('metadata.title')}
        description="Plux, a app do carro! Agende serviços sem telefonemas em 5 minutos: troca de pneus, revisão geral, substituição de baterias, reparação de pintura e mais!"
      />
      <Container maxWidth="800px">
        <Box background="white.100" borderRadius="default" p={4}>
          <TextContent namespace={ABOUT_NAMESPACE} contenti18="content" />
        </Box>
      </Container>
    </Box>
  );
}

export default About;
