import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { HOME_NAMESPACE } from "./locales";
import { default as PT } from "./locales/pt.json";
import { default as EN } from "./locales/en.json";

export { default as Home } from "./components/Home";

export class HomeModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.pt, HOME_NAMESPACE, PT);
    registerResourceBundle(SUPPORTED_LANGUAGES.en, HOME_NAMESPACE, EN);
  }
}
