import { Box, Flex, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HOME_NAMESPACE } from "../locales";
import { ServiceItem } from "./ServicesCarousel";

interface Props {
  item: ServiceItem;
}

export const ServicesCarouselElement: React.FC<Props> = ({ item }) => {
  const { t } = useTranslation(HOME_NAMESPACE);

  return (
    <Box height={["270px", "320px"]} mx="auto">
      <Box
        height="50%"
        position="relative"
        backgroundImage={item.background}
        backgroundSize="cover"
        backgroundPosition="center"
        backgroundRepeat="no-repeat"
        borderTopRadius="default">
        <Box width="100%" height="100%" backgroundColor="brand.200" borderTopRadius="default" />
        <Box position="absolute" backgroundColor="brand.100" top={3} left={3} borderRadius="default" px={2} py={1}>
          <Heading variant="h6" color="white.100">{t(item.price)}</Heading>
        </Box>
      </Box>
      <Flex
        position="relative"
        flexDirection="column"
        height="50%"
        background="white.100"
        borderBottomRadius="default"
        justifyContent="center"
        alignItems="center">
        <Box position="absolute" background="white.100" borderRadius="default" p={2} top="-35px">
          <item.icon width="48px" height="48px" style={{ minWidth: "48px" }} />
        </Box>
        <Box maxWidth="80%" textAlign="center">
          <Heading variant="h3">{t(item.title)}</Heading>
          <Heading variant="h6" color="primary.200" mt={1}>{t(item.subtitle)}</Heading>
        </Box>
      </Flex>
    </Box>
  );
};
