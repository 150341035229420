import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { ABOUT_NAMESPACE } from "./locales";
import { default as PT } from "./locales/pt.json";
import { default as EN } from "./locales/en.json";

export { default as About } from "./components/About";

export class AboutModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.pt, ABOUT_NAMESPACE, PT);
    registerResourceBundle(SUPPORTED_LANGUAGES.en, ABOUT_NAMESPACE, EN);
  }
}
