import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { LEGAL_NAMESPACE } from "./locales";
import { default as PT } from "./locales/pt.json";
import { default as EN } from "./locales/en.json";

export { default as Legal } from "./components/Legal";

export class LegalModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.pt, LEGAL_NAMESPACE, PT);
    registerResourceBundle(SUPPORTED_LANGUAGES.en, LEGAL_NAMESPACE, EN);
  }
}
