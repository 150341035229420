import { AppModule } from "AppModule";
import { registerResourceBundle, SUPPORTED_LANGUAGES } from "i18n";
import { ACCOUNT_NAMESPACE } from "./locales";
import { default as PT } from "./locales/pt.json";
import { default as EN } from "./locales/en.json";

export { default as DeleteAccount } from "./components/DeleteAccount";

export class AccountModule implements AppModule {
  setupTranslations() {
    registerResourceBundle(SUPPORTED_LANGUAGES.pt, ACCOUNT_NAMESPACE, PT);
    registerResourceBundle(SUPPORTED_LANGUAGES.en, ACCOUNT_NAMESPACE, EN);
  }
}
