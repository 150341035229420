import React from "react";
import ReactDOM from "react-dom";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { HomeModule } from "app/home";
import { AppModule } from "AppModule";
import { SharedModule } from "app/shared";
import { LegalModule } from "app/legal";
import "./index.css";
import { AboutModule } from "app/about";
import { AccountModule } from "app/account";

// Load application modules
const modules: AppModule[] = [
  new SharedModule(),
  new HomeModule(),
  new LegalModule(),
  new AboutModule(),
  new AccountModule()
];
modules.forEach((module) => module.setupTranslations());

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
