import { Box, Container } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { ACCOUNT_NAMESPACE } from "../locales";
import { Button, Flex, FormControl, FormErrorMessage, FormLabel, Heading, Input } from "@chakra-ui/react";
import { Field, FieldProps, Form, Formik } from "formik";
import * as Yup from "yup";
import axios from "network";
import { useTranslation } from "react-i18next";
import { useSearchParams } from "react-router-dom";
import { SUPPORTED_LANGUAGES } from "i18n";

interface FormState {
  email: string;
  code?: string;
}

const DeleteAccount: React.FC = () => {
  const [step, setStep] = useState("");
  const { t, i18n } = useTranslation(ACCOUNT_NAMESPACE);
  const [searchParams] = useSearchParams();

  const formInitialState = { email: searchParams.get("email") ?? "", code: searchParams.get("code") ?? undefined };
  const FormValidationSchema = Yup.object().shape({
    email: Yup.string().email(t("deleteAccount.validEmail")).required(t("deleteAccount.required"))
  });

  useEffect(() => {
    const lang = searchParams.get("lang");

    if (lang && Object.values(SUPPORTED_LANGUAGES).includes(lang)) {
      i18n.changeLanguage(lang);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box width="100%" height="100%" minHeight="100vh" backgroundColor="primary.400" py={5}>
      <Container maxWidth="500px">
        <Heading variant="h2" textAlign="center" mb={7}>{t("deleteAccount.title")}</Heading>

        {step === "EMAIL_SENT" && (
          <Heading variant="h5" textAlign="center">{t("deleteAccount.emailSent")}</Heading>
        )}

        {step === "DELETED" && (
          <Heading variant="h5" textAlign="center">{t("deleteAccount.deleted")}</Heading>
        )}

        {step === "" && (
          <Formik<FormState>
            initialValues={{ ...formInitialState }}
            validationSchema={FormValidationSchema}
            onSubmit={async (values, actions) => {
              try {
                await axios.delete(`users?email=${encodeURIComponent(values.email)}${values.code ? `&code=${values.code}` : ""}`);
                actions.resetForm({ values: { ...formInitialState } });
                setStep(values.code ? "DELETED" : "EMAIL_SENT");
              } catch (err: any) {
                actions.setFieldError("email", err.response?.data?.title ?? err.message);
              }

              actions.setSubmitting(false);
            }}>
            {(props) => {
              return (
                <Form style={{ width: "100%" }}>
                  <Flex flexDirection={["column", "row"]} width="100%">
                    <Field name="email">
                      {({ field, form }: FieldProps) => (
                        <FormControl
                          isRequired
                          isDisabled={formInitialState.code !== undefined}
                          isInvalid={
                            form.errors[field.name] !== undefined &&
                            form.touched[field.name] !== undefined
                          }
                          ml={[0, 1]} mt={[5, 0]}>
                          <FormLabel>
                            {t("deleteAccount.email")}
                          </FormLabel>
                          <Input {...field} type="email" />
                          <FormErrorMessage>
                            {form.errors[field.name]}
                          </FormErrorMessage>
                        </FormControl>
                      )}
                    </Field>
                  </Flex>

                  <Flex width="100%" flexDirection={["column", "row"]} justifyContent="space-between" alignItems={["flex-start", "center"]} mt={5}>
                    <Button
                      type="submit"
                      variant="primary"
                      isLoading={props.isSubmitting}
                      iconSpacing="20px"
                      mt={[5, 0]}>
                        {formInitialState.code ? t("deleteAccount.confirm") : t("deleteAccount.submit")}
                    </Button>
                  </Flex>
                </Form>
              );
            }}
          </Formik>
        )}
      </Container>
    </Box>
  );
}

export default DeleteAccount;
