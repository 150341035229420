import { Box, Flex, FlexProps, Heading } from "@chakra-ui/react";
import React from "react";
import { useTranslation } from "react-i18next";
import { HOME_NAMESPACE } from "../locales";
import { HowItWorksItem } from "./HowItWorks";

interface Props extends FlexProps {
  item: HowItWorksItem;
}

export const HowItWorksElement: React.FC<Props> = ({ item, ...rest }) => {
  const { t } = useTranslation(HOME_NAMESPACE);

  return (
    <Flex alignItems="center" backgroundColor="white.100" borderRadius="default" px={3} py={7} {...rest}>
      <item.icon width="40px" height="40px" style={{ minWidth: "40px" }} />
      <Box ml={5}>
          <Heading variant="h3">{t(item.title)}</Heading>
          <Heading variant="h6" color="primary.200" mt={1}>{t(item.subtitle)}</Heading>
      </Box>
    </Flex>
  );
};
