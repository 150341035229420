module.exports = {
  production: false,
  api: "https://staging.api.box.waterdog.mobi/v1/public/app",
  hCaptchaSiteKey: "bb3a7407-7085-400f-81ce-1d0d6255de71",
  automaise: {
    id: "d5c261e6-0893-eee9-570d-b6e0a749f31f",
    key: "28c48813-4209-fd07-5a81-c1451785bbc2"
  },
  // intentionally left empty
  googleAnalyticsTrackingId: "",
  googleTagManagerTrackingId: ""
};
