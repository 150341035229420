import {
  Box,
  Button,
  Divider,
  FormControl,
  FormLabel,
  Heading,
  HStack,
  Input,
  Text,
  Textarea,
  VStack,
} from "@chakra-ui/react";

export const ThemeShowCase = () => {
  return (
    <>
      <Divider m={4} />
      <Heading variant="h2">Agende um serviço para a sua viatura em poucos minutos.</Heading>
      <Text>Faça o download grátis e usufrua das muitas vantagens que oferecemos.</Text>
      <Divider m={4} />
      <Heading>Buttons</Heading>
      <HStack>
        <Box w={100}>
          <Button>Button label</Button>
        </Box>
        <Box w={100}>
          <Button disabled={true}>Button label</Button>
        </Box>
        <Box w={100}>
          <Button variant="secondary">Button label</Button>
        </Box>
      </HStack>
      <Divider m={4} />
      <Heading>Color palette</Heading>
      <VStack>
        <HStack>
          <Box w={100}>White</Box>
          <Box w={100} h={100} m={4} bg="white.100"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Background</Box>
          <Box w={100} h={100} m={4} bg="background.100"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Brand</Box>
          <Box w={100} h={100} m={4} bg="brand.100"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Primary</Box>
          <Box w={100} h={100} m={4} bg="primary.100"></Box>
          <Box w={100} h={100} m={4} bg="primary.200"></Box>
          <Box w={100} h={100} m={4} bg="primary.300"></Box>
          <Box w={100} h={100} m={4} bg="primary.400"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Secondary</Box>
          <Box w={100} h={100} m={4} bg="secondary.100"></Box>
          <Box w={100} h={100} m={4} bg="secondary.200"></Box>
          <Box w={100} h={100} m={4} bg="secondary.300"></Box>
          <Box w={100} h={100} m={4} bg="secondary.400"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Positive Feedback</Box>
          <Box w={100} h={100} m={4} bg="feedback.positive.100"></Box>
          <Box w={100} h={100} m={4} bg="feedback.positive.200"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Negative Feedback</Box>
          <Box w={100} h={100} m={4} bg="feedback.negative.100"></Box>
          <Box w={100} h={100} m={4} bg="feedback.negative.200"></Box>
        </HStack>
        <HStack>
          <Box w={100}>Alert Feedback</Box>
          <Box w={100} h={100} m={4} bg="feedback.alert.100"></Box>
          <Box w={100} h={100} m={4} bg="feedback.alert.200"></Box>
        </HStack>
      </VStack>
      <Heading>Forms</Heading>
      <VStack>
        <FormControl>
          <FormLabel htmlFor="name">Name</FormLabel>
          <Input id="name" type="text" />
        </FormControl>
        <FormControl>
          <FormLabel htmlFor="description">Mensagem</FormLabel>
          <Textarea id="description" />
        </FormControl>
      </VStack>
      <Divider m={4} />
    </>
  );
};
