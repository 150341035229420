module.exports = {
  production: false,
  api: "http://localhost:8080/public/app",
  hCaptchaSiteKey: "10000000-ffff-ffff-ffff-000000000001",
  automaise: {
    id: "d5c261e6-0893-eee9-570d-b6e0a749f31f",
    key: "28c48813-4209-fd07-5a81-c1451785bbc2"
  },
  // intentionally left empty
  googleAnalyticsTrackingId: "",
  googleTagManagerTrackingId: ""
};
