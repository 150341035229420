const Tabs = {
  variants: {
    primary: {
      tab: {
        borderTopWidth: "1px",
        borderBottomWidth: "1px",
        borderStyle: "solid",
        borderColor: "primary.300",
        padding: 4,
        _selected: {
          borderRadius: "default",
          borderStyle: "solid",
          borderColor: "primary.100",
          borderWidth: "2px",
        }
      },
      tablist: {
        justifyContent: "center",
        "& > :first-child": {
          borderLeftWidth: "1px",
          borderLeftRadius: "default",
        },
        "& > :last-child": {
          borderRightWidth: "1px",
          borderRightRadius: "default",
        }
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Tabs;
