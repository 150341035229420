import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import ReactGA from "react-ga4";
import environment from "configurations";

export const LoadAppScripts: React.FC = () => {
  const location = useLocation();
  const [scriptStarted, setScriptStarted] = useState(false);

  useEffect(() => {
    if (!scriptStarted) {
      if (environment.googleAnalyticsTrackingId) {
        ReactGA.initialize(environment.googleAnalyticsTrackingId);
      }
      if (environment.googleTagManagerTrackingId) {
        const googleTagManagerSrc = document.createElement("script");
        googleTagManagerSrc.async = true;
        googleTagManagerSrc.src = `https://www.googletagmanager.com/gtag/js?id=${environment.googleTagManagerTrackingId}`;
        document.head.appendChild(googleTagManagerSrc);
        const googleTagManagerScript = document.createElement("script");
        googleTagManagerScript.text = `window.dataLayer = window.dataLayer || []; function gtag(){dataLayer.push(arguments);} gtag('js', new Date()); gtag('config', '${environment.googleTagManagerTrackingId}');`;
        document.head.appendChild(googleTagManagerScript);
      }

      setScriptStarted(true);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (environment.googleAnalyticsTrackingId) {
      const locationUrl = `${location.pathname}${location.search}`;
      ReactGA.send({ hitType: "pageview", page: locationUrl });
    }

  }, [location]);

  return null;
}
