const Heading = {
  variants: {
    h2: {
      fontSize: ["20px", "20px", "42px", "42px"],
      fontWeight: "600",
    },
    h3: {
      fontSize: ["17px", "17px", "20px", "20px"],
      fontWeight: "600",
    },
    h4: {
      fontSize: ["15px", "15px", "17px", "17px"],
      fontWeight: "600",
    },
    h5: {
      fontSize: ["14px", "14px", "14px", "14px"],
      fontWeight: "600",
    },
    h6: {
      fontSize: ["12px", "12px", "13px", "13px"],
      fontWeight: "600",
    },
    h7: {
      fontSize: ["10px", "10px", "10px", "10px"],
      fontWeight: "600",
    },
  },
  defaultProps: {
    variant: "h3",
  },
};

export default Heading;
