import { Box } from "@chakra-ui/react";
import React from "react";
import { ClassNames } from "@emotion/react";
import { ReactComponent as TiresIcon } from "../static/Service-TireLarge.svg";
import TiresImage from "../static/Tire.jpg";
import { ReactComponent as BatteryIcon } from "../static/Service-BatteryLarge.svg";
import BatteryImage from "../static/Battery.jpg";
import { ReactComponent as BreakIcon } from "../static/Service-BreakLarge.svg";
import BreakImage from "../static/BreakDown.jpg";
import { ReactComponent as CheckupIcon } from "../static/Service-CheckupLarge.svg";
import CheckupImage from "../static/Checkup.jpg";
import { ReactComponent as ACIcon } from "../static/Service-AirconditioningLarge.svg";
import ACImage from "../static/AC.jpg";
import { ReactComponent as AccidentIcon } from "../static/Service-AccidentLarge.svg";
import AccidentImage from "../static/Accident.jpg";
import { ReactComponent as WashIcon } from "../static/Service-WashLarge.svg";
import WashImage from "../static/Wash.jpg";
import { ServicesCarouselElement } from "./ServicesCarouselElement";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/css/pagination";

export interface ServiceItem {
  title: string;
  subtitle: string;
  price: string;
  icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  background: string;
}

export const ServicesCarousel: React.FC = () => {
  const items: ServiceItem[] = [
    {
      title: "services.tire.title",
      subtitle: "services.tire.subtitle",
      price: "services.tire.price",
      icon: TiresIcon,
      background: TiresImage
    },
    {
      title: "services.battery.title",
      subtitle: "services.battery.subtitle",
      price: "services.battery.price",
      icon: BatteryIcon,
      background: BatteryImage
    },
    {
      title: "services.break.title",
      subtitle: "services.break.subtitle",
      price: "services.break.price",
      icon: BreakIcon,
      background: BreakImage
    },
    {
      title: "services.checkup.title",
      subtitle: "services.checkup.subtitle",
      price: "services.checkup.price",
      icon: CheckupIcon,
      background: CheckupImage
    },
    {
      title: "services.airConditioning.title",
      subtitle: "services.airConditioning.subtitle",
      price: "services.airConditioning.price",
      icon: ACIcon,
      background: ACImage
    },
    {
      title: "services.accident.title",
      subtitle: "services.accident.subtitle",
      price: "services.accident.price",
      icon: AccidentIcon,
      background: AccidentImage
    },
    {
      title: "services.wash.title",
      subtitle: "services.wash.subtitle",
      price: "services.wash.price",
      icon: WashIcon,
      background: WashImage
    }
  ];

  return (
    <Box
      background="linear-gradient(var(--chakra-colors-white-100), var(--chakra-colors-white-100))"
      backgroundSize="100% calc(50% - 30px)"
      backgroundPosition="top left"
      backgroundRepeat="no-repeat">
      <ClassNames>
        {({ css, cx }) => (
          <Swiper
            slidesPerView={1.5}
            spaceBetween={30}
            className={css`
                padding-bottom: 60px;
              `}
            breakpoints={{
              480: {
                slidesPerView: 2,
              },
              768: {
                slidesPerView: 3,
              },
              1024: {
                slidesPerView: 4,
              },
              1280: {
                slidesPerView: 4,
              },
              1920: {
                slidesPerView: 5,
              },
              4096: {
                slidesPerView: 6,
              },
            }}
            centeredSlides={true}
            pagination={{
              clickable: true,
              bulletActiveClass: css`
                  background: var(--chakra-colors-primary-100);
                  opacity: 100%;
                  margin-top: 20px;
                `
            }}
            modules={[Pagination]}

          >
            {items.map((item, index) => (
              <SwiperSlide key={index}>
                <ServicesCarouselElement item={item} />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </ClassNames>
    </Box>
  );
};
