import { extendTheme } from "@chakra-ui/react";
import Button from "./Button";
import Form from "./Form";
import FormLabel from "./FormLabel";
import FormError from "./FormError";
import Heading from "./Heading";
import Input from "./Input";
import Tabs from "./Tabs";
import Textarea from "./Textarea";
import Link from "./Link";

/**
 * Configures the theme based on the definitions exposed in sketch
 * Colors: https://www.sketch.com/s/685f7e1e-1638-4874-9a03-72890d34ab48/a/ZODgKPx
 */
const theme = extendTheme({
  styles: {
    global: {
      "html, body": {
        backgroundColor: "background.100",
        color: "primary.100",
        fontSize: "md"
      },
    },
  },
  breakpoints: {
    sm: "480px",
    md: "768px",
    lg: "1280px",
    xl: "1440px",
    "2xl": "1920px",
  },
  fonts: {
    heading: "Poppins",
    body: "Poppins",
  },
  fontSizes: {
    xs: "10px",
    sm: "13px",
    md: "14px",
    lg: "20px",
    xl: "42px"
  },
  colors: {
    white: { 100: "#FFFFFF" },
    background: { 100: "#FFFFFF" },
    brand: {
      100: "#6C7DBC",
      200: "rgba(108, 125, 188, 48%)",
    },
    primary: {
      100: "#212121",
      200: "rgba(33, 33, 33, 66%)",
      300: "rgba(33, 33, 33, 9%)",
      400: "rgba(33, 33, 33, 4%)"
    },
    secondary: {
      100: "#FFFFFF",
      200: "rgba(255, 255, 255, 64%)",
      300: "rgba(255, 255, 255, 30%)",
      400: "rgba(255, 255, 255, 11%)",
    },
    feedback: {
      positive: {
        100: "#297D5B",
        200: "rgba(41, 125, 91, 8%)",
      },
      negative: {
        100: "#AC2D51",
        200: "rgba(172, 45, 81, 7%)",
      },
      alert: {
        100: "#B58200",
        200: "rgba(181, 130, 0, 10%)",
      },
    },
  },
  radii: {
    none: "0",
    default: "8px",
  },
  sizes: {
    maxWidth: "1280px",
  },
  components: {
    Button,
    Input,
    Heading,
    Textarea,
    Tabs,
    Form,
    FormLabel,
    FormError,
    Link,
  },
});

export default theme;
