import { Box } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";
import { Header } from "./Header";
import { Content } from "./Content";
import { JoinNetwork } from "./JoinNetwork";
import { Footer } from "app/shared";
import environment from "configurations";

const Home: React.FC = () => {
  const [scriptStarted, setScriptStarted] = useState(false);
  useEffect(() => {
    const onScroll = function () {
      const chatToggle = document.getElementById("automaise-chat-toggle");

      // Hide chat toggle when on bottom of page to prevent hiding social icons
      if (chatToggle) {
        const isBottomOfPage = window.innerHeight + window.scrollY >= document.body.offsetHeight;

        if (isBottomOfPage) {
          chatToggle.style.visibility = "hidden";
        } else {
          if (chatToggle.style.visibility === "hidden") {
            chatToggle.style.visibility = "visible";
          }
        }
      }
    }

    if (!scriptStarted) {
      if (environment.automaise) {
        const script = document.createElement("script");
        script.id = "atmz";
        script.setAttribute("atmz-id", environment.automaise.id);
        script.setAttribute("atmz-key", environment.automaise.key);
        script.src = "https://static.automaise.com/WebChat/versions/2.0.6/index.js";
        document.head.appendChild(script);
      }

      window.addEventListener("scroll", onScroll);
      setScriptStarted(true);
    }

    return () => {
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box>
      <Header />
      <Content />
      <JoinNetwork />
      <Footer />
    </Box>
  );
}

export default Home;
