import { Global } from "@emotion/react";
const Fonts = () => (
  <Global
    styles={`
      /* latin */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-display: swap;
        src: url('/fonts/Poppins-Medium.ttf') format('truetype');
      }
      /* latin */
      @font-face {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-display: swap;
        src: url('/fonts/Poppins-SemiBold.ttf') format('truetype');
      }
      `}
  />
);

export default Fonts;
