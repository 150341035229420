const Input = {
  variants: {
    primary: {
      field: {
        bg: "primary.300",
        borderRadius: "default",
        _focus: {
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "primary.100"
        },
        _invalid: { 
          borderWidth: "2px",
          borderStyle: "solid",
          borderColor: "feedback.negative.100"
        }
      },
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Input;
