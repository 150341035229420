const Button = {
  variants: {
    primary: {
      color: "white.100",
      bg: "primary.100",
      p: "22px 13px",
      borderRadius: "default",
      _hover: {
        background: "rgba(33, 33, 33, 89%)",
      },
      _focus: {
        background: "rgba(33, 33, 33, 70%)"
      },
      _disabled: {
        background: "rgba(33, 33, 33, 9%)"
      }
    },
    secondary: {
      color: "white.100",
      bg: "brand.100",
      p: "22px 13px",
      borderRadius: "default",
    },
    ghost: {
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Button;
