import { Box, Button, Flex, Heading, IconButton, Link } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { SHARED_NAMESPACE } from "app/shared";
import { Link as ReactLink } from "react-router-dom";
import { ReactComponent as LogoIcon } from "./static/Icon.svg";
import { ReactComponent as TiktokIcon } from "./static/SocialTiktok.svg";
import { ReactComponent as InstagramIcon } from "./static/SocialInstagram.svg";
import { ReactComponent as TwitterIcon } from "./static/SocialTwitter.svg";
import { ReactComponent as LinkedInIcon } from "./static/SocialLinkedIn.svg";
import { ReactComponent as FacebookIcon } from "./static/SocialFacebook.svg";

export const Footer = () => {
  const { t } = useTranslation(SHARED_NAMESPACE);

  return (
    <Box borderTopWidth="2px" borderStyle="solid" borderColor="primary.400" py={10} mt={10}>
      <Flex alignItems="center" flexDirection={["column", "row"]} maxWidth="maxWidth" mx="auto">
        <Flex flexDirection={["column", "row"]} width={["100%", "30%"]} justifyContent={["center", "flex-start"]} alignItems="center">
          <Box width={["27px", "32px"]} opacity="40%">
            <LogoIcon width="100%" height="100%" />
          </Box>
          <Box mt={[3, 0]} ml={[0, 3]} textAlign={["center", "left"]}>
            <Heading variant="h6" color="primary.200">{t("footer.address")}</Heading>
            <Heading variant="h6" color="primary.200">{t("footer.country")}</Heading>
          </Box>
        </Flex>
        <Flex flexGrow={1} flexDirection={["column", "row"]} justifyContent="center" my={[5, 0]}>
          <Button variant="ghost" as={Link} href="https://blog.pluxapp.com" target="_blank">
            <Heading variant="h5">{t("footer.blog")}</Heading>
          </Button>
          <Button variant="ghost" as={ReactLink} to="/about" target="_blank">
            <Heading variant="h5">{t("footer.about")}</Heading>
          </Button>
          <Button variant="ghost" as={ReactLink} to="/legal?tab=terms" target="_blank">
            <Heading variant="h5">{t("footer.legalConditions")}</Heading>
          </Button>
        </Flex>
        <Flex width={["100%", "30%"]} justifyContent={["center", "flex-end"]}>
          <IconButton
            variant="ghost"
            as={Link}
            aria-label="Facebook"
            icon={<FacebookIcon />}
            href="https://www.facebook.com/pluxfixmobility"
            target="_blank"
            size="sm"
          />
          <IconButton
            variant="ghost"
            as={Link}
            aria-label="Instagram"
            icon={<InstagramIcon />}
            href="https://www.instagram.com/pluxfixmobility"
            target="_blank"
            size="sm"
            ml={5}
          />
          <IconButton
            variant="ghost"
            as={Link}
            aria-label="Twitter"
            icon={<TwitterIcon />}
            href="https://twitter.com/pluxfixmobility"
            target="_blank"
            size="sm"
            ml={5}
          />
          <IconButton
            variant="ghost"
            as={Link}
            aria-label="LinkedIn"
            icon={<LinkedInIcon />}
            href="https://www.linkedin.com/company/plux-fix-mobility"
            target="_blank"
            size="sm"
            ml={5}
          />
          <IconButton
            variant="ghost"
            as={Link}
            aria-label="TiktokIcon"
            icon={<TiktokIcon />}
            href="https://www.tiktok.com/@pluxfixgmobility"
            target="_blank"
            size="sm"
            ml={5}
          />
        </Flex>
      </Flex>
    </Box>
  );
};
