const Link = {
  variants: {
  },
  baseStyle: {
    _hover: {
      textDecoration: "none"
    },
  },
  defaultProps: {
    variant: "primary",
  },
};

export default Link;
